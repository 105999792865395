import React, { useState, createContext } from "react"
import MultistepForm from "../../../utilities/multistepForm"
import Slide from "../../../utilities/multistepForm/slide"
import style from "./calculator.module.scss"
import InputArea from "./inputArea"
import useTotal from "./hooks/total"
import { useCalculations } from "./hooks/useCalculations"
import useFanRecommendation from "./hooks/useFanRecommendation"
import useFilterRecommendation from "./hooks/useFilterRecommendation"
import Results from "./results"
import scrollToTop from "../../../globals/scrollToTop"

const initialValues = {
  individualWattage: "",
  numberOfLights: "",
  totalWattage: "",
}

const formSizes = [{ large: 560, small: 500 }, 1150]

export const CalculatorContext = createContext(null)

export default () => {
  const [values, setValues] = useState(initialValues)
  const [totalInput, setTotalInput] = useState(false)

  const total = useTotal(values, totalInput)

  // Get the CMH using the useCalculations hook
  const cmh = useCalculations(total)

  // Get the reccomended fan using the useFanRecommendation hook
  const recommendationFan = useFanRecommendation(cmh)

  // Get the reccomended filter using the useFilterRecommendation hook with the chosen fan as a parameter
  const recommendationFilter = useFilterRecommendation(cmh, recommendationFan)

  const context = {
    values: values,
    setValues: setValues,
    totalInput: totalInput,
    setTotalInput: setTotalInput,
    fan: recommendationFan,
    filter: recommendationFilter,
    cmh: cmh,
  }
  const restart = goToStart => {
    setValues(initialValues)
    scrollToTop()
    goToStart()
  }

  const seeResults = nextSlide => {
    if (cmh) {
      nextSlide()
      scrollToTop()
    } else alert("Enter details about your lights to see our recommendation")
  }

  return (
    <div className={style.container}>
      <CalculatorContext.Provider value={context}>
        <MultistepForm sizes={formSizes} maxSlides={2}>
          {({ nextSlide, goToStart }) => (
            <>
              <Slide index={1}>
                <h2>
                  Enter the wattage of your lights to see the recommended fan
                  and filter combination
                </h2>
                <InputArea />
                <button
                  onClick={() => seeResults(nextSlide)}
                  className={style.navButton}
                >
                  Get Results
                </button>
              </Slide>
              <Slide index={2}>
                <Results />
                <button
                  className={style.navButton}
                  onClick={() => restart(goToStart)}
                >
                  Calculate Again
                </button>
              </Slide>
            </>
          )}
        </MultistepForm>
      </CalculatorContext.Provider>
    </div>
  )
}
