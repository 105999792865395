import { useStaticQuery, graphql } from "gatsby"

export default cmh => {
  // Get hyperfan from Sanity CMS using graphql query
  const data = useStaticQuery(graphql`
    {
      sanityProduct(title: { eq: "Hyperfan" }) {
        card_image {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
        slug {
          current
        }
        options_with_values {
          attribute_1_values {
            cmh
            size
            values
            sub_variants {
              bed_depth
              cfm
              cmh
              decibels
              diameter
              wattage
              weight
            }
            cmh
          }
        }
      }
    }
  `)

  const fan = data.sanityProduct

  return findFan(fan, cmh)
}

// useFanRecommendation hook to get a recommendation depending on the cmh
const findFan = (fan, cmh) => {
  // extract the variants into its own variable
  const variants = fan.options_with_values.attribute_1_values

  let closestFan = findClosestFan(variants, cmh)

  // Extract the amount needed into its own object value and return it with the variant
  closestFan.image = fan.card_image.asset.fluid
  closestFan.slug = fan.slug.current
  // Return the variant
  return closestFan
}

const findClosestFan = (variants, cmh) => {
  // Initialise the closest fan as an empty variable
  let closestFan = ""

  // Initialise the amount of fans needed as 1
  let amountNeeded = 1

  // Loop through the variants until a closest is found
  while (!closestFan) {
    variants.forEach(variant => {
      if (variant.cmh * amountNeeded > cmh) {
        if (!closestFan) {
          // If the current variant multiplied by the amount of fans is more than the required CMH then save this as the closest variable
          closestFan = variant
          closestFan.amount = amountNeeded
        }
      }
    })

    // If the variant and amount was not enough then increase the number of fans and try again
    if (closestFan === "") {
      amountNeeded = amountNeeded + 1
    }
  }

  return closestFan
}
