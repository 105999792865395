import React from "react"
import { useSpring, animated } from "react-spring"
import "./modal.css"

// Modal component
const Modal = ({ children, showModal, setShowModal }) => {
  // Animation for the modal itself
  const modalSpring = useSpring({
    opacity: showModal ? 1 : 0,
    transform: `scale(${showModal ? 1 : 0.4})`
  })

  // Animation for backdrop
  const backdropSpring = useSpring({
    opacity: showModal ? 1 : 0
  })

  // Ensuring the modal or backdrop is not clickable when not in use
  const removeClick = useSpring({
    visibility: showModal ? "visible" : "hidden"
    // delay: showModal ? 0 : 800
  })

  return (
    // Overall wraooer
    <animated.div className="modal-wrapper" style={removeClick}>
      {/* Backdrop */}
      <animated.div
        className="backdrop"
        style={backdropSpring}
        onClick={() => setShowModal(false)}
      />
      {/* Actual modal */}
      <animated.div className="modal" style={modalSpring}>
        {/* Exit button */}
        <span style={{ display: "flex", justifyContent: "center" }}>
          <button onClick={() => setShowModal(false)} class="exit">
            X
          </button>
        </span>
        {/* Children */}
        {children}
      </animated.div>
    </animated.div>
  )
}

export default Modal
