// useCalculation hook to get the CMH 
export const useCalculations = (wattage) => {

    // Initialize with empty variable
    let cmh = ''

    if (wattage > 7200) {
        // If the wattage is more than 7200 take away 7200 and set CMH as 4800 for now
        cmh = 4800
        // Get the remaining wattage and multiply it by 0.34
        let remaining = wattage - 7200
        let remainingCmh = remaining * 0.34
        // Add the two CMH values together for the total
        cmh = cmh + remainingCmh
    } else {
        // If the wattage is less than 7200 then multiply the wattage by 0.67
        cmh = wattage * 0.67
    }
    // Round up to the nearest 50
    cmh = Math.round(cmh / 50) * 50
    // return the CMH
    return cmh
}