import React from "react"
import Image from "gatsby-image"
import styles from "./recommendedBlock.module.scss"
import airflow from "../../../../../../images/cmh.svg"
import decibels from "../../../../../../images/decibels.svg"
import diameter from "../../../../../../images/diameter.svg"
import wattage from "../../../../../../images/wattage.svg"
import weight from "../../../../../../images/weight.svg"
import bedDepth from "../../../../../../images/bed-depth.svg"

const RecommendedBlock = ({ title, variant }) => {
  console.log(title, variant)
  return (
    <>
      {variant.sub_variants[0] ? (
        <div className={styles.container}>
          <a
            href={"/" + variant.slug}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className={styles.specs}>
              <h2 className={styles.title}>
                {variant.amount > 1 && `${variant.amount} x `}
                {variant.size} {title}
              </h2>

              {/* Diameter */}
              {variant.sub_variants[0].diameter && (
                <span className={styles.spec}>
                  <img src={diameter} alt="Diameter icon" />
                  <h2>{variant.sub_variants[0].diameter} mm</h2>
                </span>
              )}

              {/* Bed Depth (if set) */}
              {variant.sub_variants[0].bed_depth && (
                <span className={styles.spec}>
                  <img src={bedDepth} alt="Bed depth icon" />
                  <h2>{variant.sub_variants[0].bed_depth} mm</h2>
                </span>
              )}

              {/* Weight */}
              {variant.sub_variants[0].weight && (
                <span className={styles.spec}>
                  <img src={weight} alt="Weight icon" />
                  <h2>{variant.sub_variants[0].weight} kg</h2>
                </span>
              )}

              {/* Wattage */}
              {variant.sub_variants[0].wattage && (
                <span className={styles.spec}>
                  <img src={wattage} alt="Wattage icon" />
                  <h2>{variant.sub_variants[0].wattage} W</h2>
                </span>
              )}

              {/* CMH */}
              {variant.sub_variants[0].cmh && (
                <span className={styles.spec}>
                  <img src={airflow} alt="Airflow icon" />
                  <h2>{variant.sub_variants[0].cmh} m³/h</h2>
                </span>
              )}

              {/* CFM */}
              {variant.sub_variants[0].cfm && (
                <span className={styles.spec}>
                  <img src={airflow} alt="Airflow icon" />
                  <h2>{variant.sub_variants[0].cfm} ft³/min</h2>
                </span>
              )}

              {/* Decibels */}
              {variant.sub_variants[0].decibels && (
                <span className={styles.spec}>
                  <img src={decibels} alt="Decibels icon" />
                  <h2>{variant.sub_variants[0].decibels} dB</h2>
                </span>
              )}
            </div>

            <div className={styles.image}>
              <Image fluid={variant.image} />
            </div>
          </a>
        </div>
      ) : (
        ""
      )}
    </>
  )
}

export default RecommendedBlock
