import React, { useState, useContext } from "react"
import style from "./results.module.scss"
import { CalculatorContext } from "../index"
import useProductsQuery from "../queries/productsForResults"
import RecommendedBlock from "./recommendedBlock"
import Modal from "../../../../utilities/modal"

export default () => {
  const queriedProducts = useProductsQuery()

  const { fan, filter, cmh } = useContext(CalculatorContext)

  // Get the CFM from the CMH
  const resultCFM = cmh * 0.59

  filter.amount = fan.amount

  filter.sub_variants = filter.sub_variants.filter(
    variant => variant.cmh > fan.cmh
  )

  filter.sub_variants = filter.sub_variants

  const products = {
    hyperfan: fan,
    silenced: queriedProducts.filter(
      product => product.title === "Silenced Hyperfan"
    )[0],
    filter: filter,
  }

  products.silenced.variant = products.silenced.options_with_values.attribute_1_values.filter(
    variant => variant.cmh === fan.cmh
  )[0]
  products.silenced.variant.amount = fan.amount
  products.silenced.variant.size = fan.size
  products.silenced.variant.image = products.silenced.card_image.asset.fluid
  products.silenced.variant.slug = "silenced-hyperfan"

  // State variable for wether the more info modal is shown
  const [showModal, setShowModal] = useState(false)

  return (
    <div className={style.container}>
      <h2>Your fans need to extract:</h2>
      <div className={style.resultWrapper}>
        <h2 className={style.result}>{cmh} m³/h</h2>
        <p>meters cubed of air each hour</p>
      </div>
      <p className={style.or}>or</p>
      <div className={style.resultWrapper}>
        <h2 className={style.result}>{resultCFM} ft³/min</h2>
        <p>cubic feet of air per minute</p>
      </div>
      {/* Disclaimer */}
      <button
        className={style.disclaimerBtn}
        onClick={() => setShowModal(!showModal)}
      >
        Accuracy may vary.
      </button>
      <h2>Recommended fan & filter</h2>
      <h3>Fan:</h3>
      <div className={style.fans}>
        <RecommendedBlock title="Hyperfan" variant={fan} />
        <RecommendedBlock
          title="Silenced Hyperfan"
          variant={products.silenced.variant}
        />
      </div>
      <h3>Filter:</h3>
      <div className={style.filter}>
        <RecommendedBlock title="Phresh Filter" variant={filter} />
      </div>
      {/* Disclaimer modal */}
      <Modal showModal={showModal} setShowModal={setShowModal}>
        <p>
          The above results are a guide and not absolute. Some rooms naturally
          run cooler or warmer. Airflow requirements will differ depending on
          outside temperature, time of year and light type.
        </p>
      </Modal>
    </div>
  )
}
