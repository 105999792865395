import React from "react"
import style from "./button.module.scss"

export default ({ noBorder, className, children, onClick }) => (
  <button
    onClick={onClick}
    className={`${style.button} ${noBorder && style.noBorder} ${className &&
      className}`}
  >
    {children}
  </button>
)
