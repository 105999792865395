import { useStaticQuery, graphql } from "gatsby"

export default () => {
  const data = useStaticQuery(graphql`
    {
      allSanityProduct {
        nodes {
          title
          card_image {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          slug {
            current
          }
          options_with_values {
            attribute_1_values {
              sub_variants {
                bed_depth
                cfm
                cmh
                decibels
                diameter
                wattage
                weight
              }
              cmh
              size
            }
          }
        }
      }
    }
  `)
  return data.allSanityProduct.nodes
}
