import React, { useContext } from "react"
import style from "./multistepForm.module.scss"
import { MultistepContext } from "./index"

export default ({ children, index }) => {
  const { activeSlide, setActiveSlide } = useContext(MultistepContext)

  //   Give classname to div depending on active slide
  const slideClass =
    activeSlide > index ? style.previous : activeSlide === index && style.active
  return <div className={`${style.slide} ${slideClass}`}>{children}</div>
}
