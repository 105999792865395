import React, { useState, useContext } from "react"
import style from "./inputArea.module.scss"
import Button from "../../../../utilities/button"
import { CalculatorContext } from "../index"

export default () => {
  const { values, setValues, totalInput, setTotalInput } = useContext(
    CalculatorContext
  )

  // Event handler for inputs
  const handleChange = event => {
    event.preventDefault()
    //Get name and value of input
    const { name, value } = event.target
    // Setting the state with the above values
    setValues({
      ...values,
      [name]: value
    })
  }

  return (
    <div className={style.inputArea}>
      {!totalInput ? (
        <>
          <div className={style.label}>
            <span className={style.labelText}>Individual Light Wattage</span>
            <WattageSelector values={values} setValues={setValues} />
          </div>
          <label>
            <span className={style.labelText}>Number of lights</span>
            <input
              value={values.numberOfLights}
              type="text"
              name="numberOfLights"
              onChange={handleChange}
              autocomplete="off"
            />
          </label>
        </>
      ) : (
        <label>
          <span className={style.labelText}>Total wattage</span>
          <input
            value={values.totalWattage}
            type="text"
            name="totalWattage"
            onChange={handleChange}
            autocomplete="off"
          />
        </label>
      )}
      <Button
        noBorder
        onClick={() => setTotalInput(!totalInput ? true : false)}
        className={style.inputTypeToggle}
      >
        {!totalInput
          ? "I know my total wattage"
          : "Calculate individual wattage"}
      </Button>
    </div>
  )
}

const WattageSelector = ({ values, setValues }) => {
  const wattList = [250, 400, 600, 750, 1000]
  return (
    <div className={style.wattSelectorArea}>
      {wattList.map(watt => (
        <button
          className={`${style.wattSelector} ${values.individualWattage ===
            watt && style.active}`}
          onClick={() => setValues({ ...values, individualWattage: watt })}
        >
          {watt} W
        </button>
      ))}
    </div>
  )
}
