import { useStaticQuery, graphql } from "gatsby"

export default (cmh, fan) => {
  // Query the sanity CMS using GraphQL
  const data = useStaticQuery(graphql`
    {
      sanityProduct(title: { eq: "Phresh Filter" }) {
        card_image {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
        slug {
          current
        }
        options_with_values {
          attribute_1_values {
            cmh
            size
            values
            sub_variants {
              bed_depth
              cfm
              cmh
              decibels
              diameter
              wattage
              weight
            }
            cmh
          }
        }
      }
    }
  `)

  const filter = data.sanityProduct

  return useFilterRecommendation(cmh, fan, filter)
}

const useFilterRecommendation = (cmh, fan, filter) => {
  // Extract the variants into its own variable
  const variants = filter.options_with_values.attribute_1_values

  // Filter down to filters that are the same size as the fan
  const sameSizeFilter = variants.filter(
    variant => variant.size === fan.size
  )[0]

  sameSizeFilter.sub_variants = sameSizeFilter.sub_variants.filter(
    variant => variant.cmh * fan.amount > cmh
  )

  // Adding image to returnable variable
  sameSizeFilter.image = filter.card_image.asset.fluid

  // Adding slug to returnable object
  sameSizeFilter.slug = filter.slug.current

  // Return the filter
  return sameSizeFilter
}
