import React, { useState, createContext } from "react"
import style from "./multistepForm.module.scss"
import { useWindowSize } from "../../globals/windowSize"

export const MultistepContext = createContext(null)

const useGetSizesBasedOnWidth = sizes => {
  const { desktop } = useWindowSize()
  if (!sizes.large) return sizes
  else return desktop ? sizes.large : sizes.small
}

export default ({ sizes, children }) => {
  const [activeSlide, setActiveSlide] = useState(1)

  //Get size based on whether on desktop or not
  sizes = sizes.map(size => useGetSizesBasedOnWidth(size))

  const sizeStyle = {
    maxHeight: sizes[activeSlide - 1]
  }

  const nextSlide = () => {
    setActiveSlide(activeSlide + 1)
  }

  const goToStart = () => {
    setActiveSlide(1)
  }

  const context = {
    activeSlide: activeSlide,
    setActiveSlide: setActiveSlide,
    goToStart: goToStart
  }

  return (
    <div className={style.container} style={sizeStyle}>
      <MultistepContext.Provider value={context}>
        {children({
          nextSlide: nextSlide,
          setActiveSlide: setActiveSlide,
          goToStart: goToStart
        })}
      </MultistepContext.Provider>
    </div>
  )
}
