import React from "react"
import Layout from "../components/layoutNew"
import Calculator from "../components/static/pageComponents/calculator"
import TwoColumns from "../components/layoutNew/innerLayouts/twoColumns"
import SEO from "../components/seo"

export default () => {
  return (
    <Layout>
      <SEO title="Grow Room Calculator" />
      <TwoColumns
        title="Airflow Calculator"
        body="Use our airflow calculator to find out which fan and filter combination is best for you."
      >
        <Calculator />
      </TwoColumns>
    </Layout>
  )
}
